.signUp-right-side {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: linear-gradient(
    135deg,
    #8C52FF 10%,
    #8C52FFaa 100%
  );
}

.title-tutorial h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 1.7rem;
  font-weight: bold;
  color: var(--white);
}

.video-player-overlay {
  width: 75%;
  height: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 14px 15px 0px rgba(0, 0, 0, 0.5);
}

.play-btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-btn svg {
  width: 80px;
  height: 80px;
  transition: 0.5s ease-in-out;
  fill: var(--primary);
} .play-btn svg:hover {
  opacity: 0.6;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  width: 60%;
  height: 60%;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}


.popup-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
  .signUp-right-side {
    height: 80vh;
    padding: 1rem;
  }
  .title-tutorial h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--white);
  }

  .video-player-overlay {
    width: 100%;
    height: 60%;
  }

  .popup-content {
    width: auto;
    height: 40%;
    box-sizing: border-box;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .signUp-right-side {
    height: 70vh;
    padding: 1rem;
    margin-top: 2rem;
  }
  .title-tutorial h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--white);
  }

  .video-player-overlay {
    width: 90%;
    height: 60%;
  }

  .popup-content {
    width: 90%;
    height: 60%;
    box-sizing: border-box;
  }
}

@media screen and (width: 1024px) {
  .signUp-right-side {
    justify-content: center;
    gap: 5rem;
  }

  .title-tutorial h2 {
    font-size: 2.3rem;
    font-weight: bold;
    color: var(--white);
  }

  .video-player-overlay {
    width: 85%;
    height: 30%;
  }

  .popup-content {
    width: 90%;
    height: 60%;
    box-sizing: border-box;
  }
}