.footer-container {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.footer-right {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer-right p {
    margin-right: 0.8rem;
}

.text-right {
    text-align: right;
}

.difficulty {
    margin-top: -0.3rem;
}

.solution-button {
    margin-top: 0.4rem;
}

.ant-input-data-count {
    bottom: -25px !important;
}

.solution-box {
    margin-top: 1rem;
}

.textarea-footer {
    margin-top: 0.4rem;
}

.textarea-footer button {
    width: 3rem;
}

.listening-container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.disabled-radio {
    color: #707070;
}

.reproducing-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
}

.simulation-body {
    margin-top: -0.5rem;
}

@media screen and (max-width: 740px) {

    .simulation-logo {
        display: none;
    }

}