.plan-card-box {
    width: 32%;
    min-width: 350px;
    padding: 1.5rem 1.8rem;
    border-radius: 0.8rem;
    border: solid 1px #e0e0e0;
    background-color: white;
}

.plan-card-content {
    display: flex;
    justify-content: space-between;
}

.plan-card-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: #404040;
}

.plan-card-subtitle {
    font-weight: 500;
    color: #808080;
    font-size: 1rem;
    line-height: 1.5rem;
}

.plan-card-description-container ul {
    list-style-type: none;
    padding: 0;
}

.plan-card-description-container ul li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.plan-card-price {
    font-size: 1.5rem;
}

.plan-card-price-old {
    text-decoration: line-through;
    color: #707070;
    margin-top: 0.1rem;
}

.plan-card-discount {
    margin-bottom: 0.7rem;
}

.plan-card-list-item-icon {
    color: var(--primary);
    margin-right: 1rem;
}

.plan-card-list-item-text,
.plan-card-list-item-text-important {
    color: #404040;
    margin-top: 0.1rem;
}

.plan-card-list-item-text-important {
    color: var(--primary);
}

.plan-card-footer {
    display: flex;
    width: 100%;
}

.plan-card-buy-button {
    margin-left: auto;
}

.plan-card-info-titles {
    height: 4rem;
}

@media screen and (max-width: 740px) {
    .plan-card-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        min-width: 0;
    }

    .plan-card-title {
        font-size: 1.15rem;
    }

    .plan-card-subtitle {
        font-size: 0.85rem;
    }

    .plan-card-price {
        font-size: 1.03rem;
    }

    .plan-card-price-container {
        text-align: end;
    }

    .plan-card-description-container ul li {
        margin: 0rem auto;
    }
}

@media screen and (min-width: 741px) and (max-width: 1023px) {}