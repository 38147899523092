.exam-name {
    margin-top: -0.6rem;
    color: #606060;
}

.alert {
    margin-bottom: 1rem;
}

.available-simulations-text {
    text-align: center;
    margin-top: 0.4rem;
}

.available-simulations-text a {
    color: var(--primary);
}

.create-simulation-modal-info-container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    right: 0.5rem;
    bottom: 0.5rem;
}

.info-icon {
    color: #0088ff;
}

.simulation-footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.create-simulation-btn {
    width: 80%;
    margin: auto;
}

@media (max-width: 430px) {
    .create-simulation-btn {
        width: 100%;
    }
}