.reset-form-section {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 30px;
}

.reset-title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: var(--primary);
}

.reset-form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.reset-form-head p {
  text-align: center;
}

.reset-formControl {
    width: 100%;
    box-sizing: border-box;
}

.reset-formControl label {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: var(--dark);
    display: block;
    margin-bottom: 0.4rem;
  }
  
  .reset-formControl input {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    border: 1px solid var(--secondary-dark);
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    display: block;
  }
  
  .reset-formControl input::placeholder {
    color: #b0b0b0;
  }

  .reset-btn {
    width: 77%;
    height: auto;
    padding: 0.625rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    background-color: var(--primary);
    color: var(--white);
    border: 0.125rem solid var(--secondary);
    cursor: pointer;
    font-size: 1.06rem;
    font-weight: 600;
    margin-top: 0.94rem;
  } .reset-btn:hover {
    opacity: 0.8;
  }

  .reset-error {
    margin-top: 3px;
  }

  .btn-reset-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* SMARTPHONE */
@media screen and (max-width: 740px) {
    .reset-form-section {
      padding: 20px;
    }

    .reset-title {
      width: 100%;
      font-size: 1.5rem;
    }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {}