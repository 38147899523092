.auth-layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 1.25rem;
    left: 2.5rem;
  }
  
  .left-side {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-side {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* SMARTPHONE */
@media screen and (max-width: 740px) {
  .auth-layout {
    flex-direction: column;
    height: auto;
  }

  .logo {
    position: initial;
  } .logo img {
    width: 9rem;
  }

  .right-side {
    width: 100%;
    height: 100%;
  }

  .left-side {
    width: 100%;
    height: auto !important;
    margin-bottom: 1rem;
    padding: 1rem;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .auth-layout {
    flex-direction: column;
    height: auto;
  }

  .logo {
    position: initial;
  } .logo img {
    width: 9rem;
  }

  .right-side {
    width: 100%;
    height: 100%;
  }

  .left-side {
    width: 100%;
    height: auto !important;
    margin-bottom: 1rem;
    padding: 0rem;
  }
}