.filter-search a {
    height: auto;
    box-sizing: border-box;
    padding: 0.8rem 1.5rem;
    border-radius: 0.4rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-space-item {
    display: flex;
    align-items: center;
    color: black;
}