.logo-main, .logo-main-grey {
    width: 11rem;
    padding: 1rem;
    object-fit: cover;
    pointer-events: none;
    user-select: none;
}

.logo-main-grey {
    width: 10rem;
    filter: grayscale(1);
    opacity: 0.4;
    padding: 0;
}