.link-registration {
    margin-top: 1.875rem;
    text-align: center;
    text-decoration: none;
    color: var(--primary);
    border-bottom: solid 1px var(--primary);
    transition: opacity 0.8;
  } .link-registration:hover {
    opacity: 0.8;
  }

  .login-form-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* SMARTPHONE */
@media screen and (max-width: 740px) {
  .login-form-container {
    height: 100%;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .login-form-container {
    height: 100%;
  }
}