.mainContainer {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1920px;
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 12px #e0e0e0;
}

.content {
  width: calc(100% - 25rem);
  background-color: #fcfcfc;
  flex: 1;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 20px 0px 0px 20px;
}

.content-navbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-titles {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.settings-menu-icon {
  margin-right: -0.3rem;
  color: #9a9a9a;
  font-size: 1rem;
  cursor: pointer;
}

.sidebar {
  width: 25rem;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  box-sizing: border-box;
  padding: 40px;
  border-left: 2px solid #e3e5e8;
  position: relative;
}

.sidebar-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-header h3 {
  margin: 0;
}

.sidebar-subtitle {
  font-weight: 500;
  margin-top: 0.4rem;
}

.sidebar-body {
  position: relative;
  height: 97%;
  box-sizing: border-box;
}

.content-body {
  margin-top: 1.5rem;
}

.navbar-btn {
  height: auto;
  box-sizing: border-box;
  padding: 0.8rem 1.3rem;
  border-radius: 0.4rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  width: 100%;
  height: 70vh;
  display: grid;
  place-items: center;
}

.header-custom-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.8rem;
}

.sidebar-toggle-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
}

.toggle-sidebar-button {
  color: var(--primary);
}

.toggle-sidebar-icon {
  font-size: 1.5rem;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
  .sidebar-toggle-container {
    display: block;
  }

  .mainContainer {
    width: 100vw;
    height: 100vh;
  }

  .container {
    box-shadow: none;
    flex-direction: column;
  }

  .content {
    width: 100%;
    padding: 1.2rem;
  }

  .content-navbar {
    display: block;
    width: 100%;
  }

  .header-left {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .content-body {
    margin-top: 1rem;
  }

  .filter-search {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .header-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .sidebar {
    display: none;
  }

  .header-custom-left {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: -1.6rem;
    gap: 2rem;
  }

  .header-titles {
    text-align: center;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .sidebar-toggle-container {
    display: block;
  }

  .mainContainer {
    width: 100vw;
    height: 100vh;
  }

  .content {
    padding: 30px;
    background-color: white;
  }

  .container {
    box-shadow: none;
  }

  .sidebar {
    display: none;
  }

  .header-custom-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.8rem;
  }
}

@media screen and (width: 1024px) {
  .sidebar-toggle-container {
    display: block;
  }

  .mainContainer {
    width: 100vw;
    height: 100vh;
  }

  .container {
    box-shadow: none;
    height: 100%;
  }

  .content {
    height: 100%;
    padding: 30px;
    background-color: white;
  }

  .sidebar {
    display: none;
  }

  .header-custom-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1920px) {
  .content-body {
    width: 100%;
    box-sizing: border-box;
  }
}
