:root {
    --primary: #8C52FF;
    --secondary-dark: #B0A8B9;
    --secondary: #FBEAFF;
    --dark: #404040;
    --light: rgb(245, 248, 222);
    --white: rgb(240, 240, 240);
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

/* --- ANT OVERRIDE --- */

.ant-form-item:last-child {
    margin-bottom: 0;
}

/* --- GENERIC CLASSES --- */

.mt-1 {
    margin-top: 1rem;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.text, .text-sm {
    color: #505050;
}

.text-sm {
    font-size: 0.8rem;
}