.signUp-form-section {
  width: 100%;
  height: auto;
  padding: 0.625rem;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.signUp-form-title {
  margin: 0;
  padding: 0;
  color: var(--primary);
}

.signUp-form-sub-title {
  margin-top: 0.625rem;
  font-weight: 600;
  color: grey;
  border-bottom: 1px solid lightgray;
  padding-bottom: 1.2rem;
}

.signUp-form {
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  margin-top: 1.25rem;
}

.label-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;
}

.signUp-formControl {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.signUp-formControl label {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark);
  margin: 0.31rem auto;
}

.signUp-formControl input, .signUp-formControl select {
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid var(--secondary-dark);
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
}

.signUp-formControl input::placeholder {
  color: #b0b0b0;
}

.signUp-btn {
  width: 100%;
  height: auto;
  padding: 0.625rem;
  border-radius: 0.625rem;
  box-sizing: border-box;
  background-color: var(--primary);
  color: var(--white);
  border: 0.12rem solid var(--secondary);
  cursor: pointer;
  font-size: 1.06rem;
  font-weight: 600;
  margin-top: 0.94rem;
} .signUp-btn:hover {
  opacity: 0.8;
} .signUp-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.signUp-error {
  margin-top: 0.19rem;
}

.button-signUp-container {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}

.signUp-google-btn {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  margin-top: 1rem;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
  .signUp-form {
    gap: 1.5rem;
    width: 100%;
  }

  .signUp-form-sub-title {
    font-size: 1rem;
    text-align: center;
  }

.signUp-formControl {
  width: 100%;
}

  .signUp-formControl label {
    font-size: 0.872rem;
  }

  .label-container {
    flex-direction: column;
    gap: 1rem;
  }

  .button-signUp-container {
    flex-direction: column;
    margin-top: 0.3rem;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .signUp-btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: -1rem;
  }

  .signUp-form {
    gap: 1.5rem;
    width: 80%;
  }

  .signUp-form-sub-title {
    font-size: 1rem;
    text-align: center;
  }

.signUp-formControl {
  width: 100%;
}

  .signUp-formControl label {
    font-size: 0.872rem;
  }

  .label-container {
    flex-direction: column;
    gap: 1rem;
  }

  .button-signUp-container {
    flex-direction: column;
    margin-top: 0.3rem;
  }
}

@media screen and (width: 1024px) {
  .signUp-form-section {
    padding: 1.5rem;
  }
}