.form-section {
  width: 400px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

.form-title {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  color: var(--primary);
}

.form-sub-title {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.2rem;
  color: grey;
}

.form {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
}

.formControl {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formControl label {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: var(--dark);
  margin-bottom: 0.5rem;
}

.formControl input:not([type='checkbox']) {
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid var(--secondary-dark);
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;
}

.formControl input::placeholder {
  color: #b0b0b0;
}

.form-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8rem;
  gap: 1rem;
}

.form-options span {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.btn {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border-radius: 0.5rem;
  padding: 10px;
  font-weight: 600;
  color: var(--light);
  cursor: pointer;
  transition: opacity 0.2s;
} .btn:hover {
  opacity: 0.8;
}

small {
  width: 100%;
  box-sizing: border-box;
  color: red;
  font-size: smaller;
  font-weight: 600;
  margin-top: 10px;
}

.remember-check {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.forget {
  text-decoration: none;
  color: var(--primary);
  border-bottom: solid 1px var(--primary);
  transition: opacity 0.8;
} .forget:hover {
  opacity: 0.8;
  cursor: pointer;
}

.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  padding-right: 50px;
}

.password-toggle-btn {
  border: none;
  position: absolute;
  top: 13px;
  right: 25px;
}

.password-toggle-btn svg {
  width: 21px;
  height: 21px;
}

.buttons-container {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
  .form-section {
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .form-options {
    margin-top: 1.4rem;
  }

  .forget,
  .remember-check {
    font-size: 0.85rem;
  }

  .forget-span {
    display: flex;
    justify-content: end;
  }

  .buttons-container {
    flex-direction: column;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .form-section {
    width: 80%;
    height: auto;
    box-sizing: border-box;
  }

  .form-options {
    margin-top: 1.4rem;
  }

  .forget-span {
    display: flex;
    justify-content: end;
  }

  .forget {
    font-size: 0.9rem;
  }

  .buttons-container {
    flex-direction: column;
  }
}