.not-found-section {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.not-found-lottie {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.not-found-text {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.not-found-title {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--primary);
    text-align: center;
    width: 60%;
    line-height: 2.5rem;
}

.not-found-btn {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    font-weight: 500;
    padding: 10px;
    color: var(--white);
    background-color: var(--primary);
} .not-found-btn:hover {
    opacity: 0.8;
    color: var(--white) !important;
    border: 1px solid var(--secondary) !important;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
    .not-found-text h4 {
        width: 80%;
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {}