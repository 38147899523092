.filter-search {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.empty-container {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-card {
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(4, 330px);
  column-gap: 2rem;
  row-gap: 2rem;
}

.sidebar-btn {
  height: 35px;
  display: flex;
  align-items: center;
}

.modal-subject {
  width: 300px;
  height: auto;
  box-sizing: border-box;
  padding: 20px;
}

.sidebar-content {
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-center {
  margin-top: 1rem;
}

.sidebar-footer {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} .sidebar-footer img {
  margin-bottom: -3rem;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
  .user-card {
    grid-template-columns: repeat(1, 330px);
    column-gap: 0rem;
    row-gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .empty-container {
    height: 53vh;
  }
}

/* TABLET */
@media screen and (min-width: 741px) and (max-width: 1023px) {
  .user-card {
    grid-template-columns: repeat(2, 330px);
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (width: 1024px) {
  .user-card {
    grid-template-columns: repeat(2, 330px);
    column-gap: 8rem;
    row-gap: 8rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .user-card {
    grid-template-columns: repeat(1, 330px);
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1579px) {
  .user-card {
    grid-template-columns: repeat(2, 330px);
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1580px) and (max-width: 1900px) {
  .user-card {
    grid-template-columns: repeat(3, 330px);
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}
