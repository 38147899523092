h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: #404040;
    margin: 0;
    margin-bottom: 0.3rem;
}

h5 {
    color: #a0a0a0;
}

p {
    margin: 0;
    line-height: 2rem;
}