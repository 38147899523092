.google-btn {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid var(--secondary-dark);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
} .google-btn:hover {
    background-color: var(--secondary);
}

.google-btn p {
    font-weight: 600;
}

.google-btn img {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: cover;
    margin-right: 10px;
    overflow: hidden;
}

@media screen and (min-width: 431px) and (max-width: 825px) {
    .google-btn {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
}