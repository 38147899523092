.submit-button {
    width: 100%;
}

.settings-card {
    padding: 2rem;
    border-radius: 0.8rem;
    background-color: white;
    box-shadow: 0px 0px 20px #f0f0f0;
}

.avatar-container {
    width: 102px;
    height: 102px;
    margin-inline-end: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.3s;
    display: grid;
    place-items: center;
    overflow: hidden;
} .avatar-container:hover {
    border-color: var(--primary);
}

.avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.file-upload {
    position: absolute;
    left: 0;
    visibility: hidden;
}

.available-simulations {
    margin-top: 1.1rem;
}

.plans-box {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    overflow-x: scroll;
}

.modal-success-payment-content {
    width: 100%;
    display: grid;
    place-items: center;
}

.payment-problems-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-top: -1.2rem;
}

.payment-problems-container img {
    width: 10rem;
}

.payment-problems-container a {
    text-decoration: none;
    border-bottom: solid 1px;
    color: var(--primary);
}

@media screen and (max-width: 430px) {
    .plans-box {
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        box-sizing: border-box;
        gap: 2rem;
        margin: 2rem auto;
    }   

    .payment-problems-container {
        width: 100%;
        box-sizing: border-box;
    } .payment-problems-container p {
        text-align: center;
    }
    
    .header-logo {
        display: none;
    }
}

@media screen and (min-width: 431px) and (max-width: 825px) {
    .plans-box {
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        box-sizing: border-box;
        gap: 2rem;
        margin: 2rem auto;
    }  
    
    .payment-problems-container {
        width: 100%;
        box-sizing: border-box;
    }

    .header-logo {
        display: none;
    }
}

@media screen and (width: 1024px) {
    .plans-box {
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        box-sizing: border-box;
        gap: 2rem;
        margin: 2rem auto;
    }

    .payment-problems-container {
        width: 100%;
        box-sizing: border-box;
    }
}