.text {
    margin-bottom: 1rem;
}

.exam-date {
    margin-top: 0.1rem;
    font-size: 0.7rem;
}

.simulations-title {
    margin-bottom: 1rem;
}

.simulation-creation-date {
    margin-bottom: 0.8rem;
}

.sidebar-footer-exam {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.exam-card-results-row {
    width: 100%;
    display: flex;
    gap: 1rem;
}

/* SMARTPHONE */
@media screen and (max-width: 740px) {
    .exam-card-results-row {
        flex-direction: column;
    }
}