.card {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #ccc;
    padding: 0.6rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-btn {
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
}

.subject-menu-item {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}