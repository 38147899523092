.recovery-modal {
    box-sizing: border-box;
}

.recovery-modal label {
    margin: 1.25rem auto;
}

.recovery-input {
    margin: 1.25rem auto;
}

.recovery-btn {
    margin: 0;
    width: 60%;
    height: auto;
    background-color: var(--primary);
    font-size: 1.06rem;
    font-weight: 600;
    border: 0.125rem solid var(--secondary);
    color: white;
}

.recovery-btn:hover {
    color: white !important;
    border-color: var(--secondary) !important;
}