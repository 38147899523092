.create-subject-form-input {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.create-subject-form-input label,
.create-subject-form-input input {
    width: 100%;
    box-sizing: border-box;
    margin: 20px auto;
    padding: 10px auto;
}

.create-subject-input {
    border: 2px solid var(--secondary) !important;
}

.create-subject-btn {
    width: 100%;
    height: auto;
}